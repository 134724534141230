//// ----------------------------------------------------------------------
///
/// Notification
///
/// @group components
/// @module sass/components/alerts/notification
/// @require mixin es-to-rem
///
//// ----------------------------------------------------------------------

.es-notification {

  //Notification variables
  $es-notification-color: es-color("mono", "oslo-grey");

  //Mixin for notification type color and style
  @mixin es-notification-type($color){
    border-color: $color;
    color: $color;
    & .es-icon:first-child {
      background-color: $color;
    }
    & p {
      a {
        color: $color;
      }
    }
  }

  background-color: $es-background-color;
  border: 1px solid $es-border-color;
  color: $es-font-color;
  display: flex;
  padding: es-to-rem($es-space-inset-xsmall);

  // Notification main icon
  & .es-icon:first-child {
    background-color: $es-notification-color;
    color: $es-font-bright-color;
    font-size: es-to-rem($es-icon-size-xlarge);
    padding: es-to-rem($es-space-inset-default);
  }

  // Notification close icon
  & .es-icon:last-child {
    color: $es-font-color;
    cursor: pointer;
    font-weight: bold;
    padding: es-to-rem($es-space-inset-small);
    transition: 0.2s;

    &:hover {
      color: $es-font-subtle-color;
    }
  }

  & p {
    align-self: center;
    flex: 1;
    margin: es-to-rem($es-space-gutter-left-default);

    //Overflow handling
    @include es-overflow-wrap;
    overflow-x: auto;

    a {
      color: $es-font-color;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  // Notification types

  &.-es-danger {
    @include es-notification-type($es-danger-color);
  }

  &.-es-info {
    @include es-notification-type($es-info-color);
  }

  &.-es-success {
    @include es-notification-type($es-success-color);
  }

  &.-es-warning {
    @include es-notification-type($es-warning-color);
  }

  // Animation.
  &.-es-animated {

    animation-duration: 0.5s;
    animation-fill-mode: both;

    &.-es-slide-in-right {
      animation-name: slide-in-right;
    }

    @keyframes slide-in-right {
      from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes fade-in-down {
      from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
      }

      to {
        opacity: 1;
        transform: none;
      }
    }

    &.-es-fade-in-down {
      animation-name: fade-in-down;
    }
  }
}

