//// ----------------------------------------------------------------------
///
/// Button component
///
/// @group components
/// @module sass/components/basics/button
///
//// ----------------------------------------------------------------------

// Global variables
  // standard button settings
  $es-button-line-height:               es-to-rem($es-line-height) !default;
  $es-button-icon-size:                 es-to-rem($es-icon-size) !default;

  // default button colors
  /// enabled state
  $es-button-default-background-color:  es-color("mono", "white") !default;
  $es-button-default-text-color:        es-color("blue", "light-blue") !default;
  $es-button-default-border-color:      es-color("blue", "light-blue") !default;
  $es-button-default-box-shadow-color:  es-color("mono", "black", .2) !default;
  /// hover state
  $es-button-default-hover-background-color:  es-color("blue", "seagull") !default;
  $es-button-default-hover-text-color:        es-color("mono", "white") !default;
  $es-button-default-hover-border-color:      es-color("blue", "seagull", .7) !default;
  /// focus state
  $es-button-default-focus-border-color:      es-color("blue", "atoll");
  /// active state
  $es-button-default-active-background-color: es-color("blue", "atoll", .8);
  /// disabled state
  $es-button-default-disabled-background-color:  es-color("mono", "white") !default;
  $es-button-default-disabled-text-color:        es-color("mono", "light-grey") !default;
  $es-button-default-disabled-border-color:      es-color("mono", "light-grey") !default;


.es-button {
  // configuration variables for button
  
  // default button dark background
  $es-button-default-dark-background-color:  transparent;
  $es-button-default-dark-text-color:        es-color("mono", "white") !default;
  $es-button-default-dark-border-color:      es-color("mono", "white") !default;
  
  // primary button colors
  /// enabled state
  $es-button-primary-background-color:  es-color("blue", "light-blue") !default;
  $es-button-primary-border-color:      es-color("blue", "light-blue") !default;
  $es-button-primary-text-color:        es-color("mono", "white") !default;
  $es-button-primary-box-shadow-color:  es-color("mono", "black", .2) !default;
  /// hover state
  $es-button-primary-hover-background-color:    es-color("blue", "seagull") !default;
  $es-button-primary-hover-box-shadow-color:    es-color("blue", "light-blue", .7) !default;
  /// focus state
  $es-button-primary-focus-border-color: es-color("blue", "atoll");
  /// disabled state
  $es-button-primary-disabled-background-color:   es-color("mono", "light-grey") !default;
  $es-button-primary-disabled-border-color:       es-color("mono", "oslo-grey") !default;
  $es-button-primary-disabled-text-color:         es-color("mono", "alto") !default;
  $es-button-primary-disabled-box-shadow-color:   es-color("mono", "black", .2) !default;
  
  // primary button dark background
  $es-button-primary-dark-background-color: es-color("mono", "white") !default;
  $es-button-pimary-dark-border-color: es-color("blue", "light-blue") !default;
  $es-button-primary-dark-text-color: es-color("blue", "light-blue") !default;
  
  
  // cancel button colors
  /// enabled state
  $es-button-danger-background-color:  es-color("red", "punch");
  $es-button-danger-border-color: es-color("red", "punch");
  $es-button-danger-box-shadow-color:  es-color("mono", "black", .2);
  $es-button-danger-text-color:        es-color("mono", "white");
  /// hover state
  $es-button-danger-hover-background-color:    es-color("red", "orange");
  $es-button-danger-hover-border-color:    es-color("red", "orange");
  /// focus state
  $es-button-danger-focus-border-color: es-color("blue", "atoll");
  /// active state
  $es-button-danger-active-background-color:      es-color("red", "dark-red");
  /// disabled state
  $es-button-danger-disabled-background-color:    es-color("mono", "light-grey");
  $es-button-danger-disabled-border-color:        es-color("mono", "oslo-grey");
  $es-button-danger-disabled-text-color:          es-color("mono", "alto");
  $es-button-danger-disabled-box-shadow-color:    es-color("mono", "black", .2);
  
  border: 1px solid;

  // Reset for round buttons on Mac/Ios
  // @TODO: This should be separated into a reset file (with all the other resets used in the SG)
  border-radius: 0;

  cursor: pointer;
  display: inline-block;
  line-height: $es-button-line-height;
  padding: es-to-rem($es-space-inset-squish-default);
  text-align: center;
  
  // disabled state
  &:disabled,
  &[disabled=disabled] {
    cursor: not-allowed;
  }

  .es-icon {
    display: inline-block;
    font-size: $es-button-icon-size;
    // harmonize icons and text vertically
    top: -(es-to-rem(2px));
    position: relative;
    color: inherit;
  }

  // space between:
  //  - icon - span and vice versa
  //  - icon - label and vice versa
  .es-icon + span,
  .es-icon + label,
  span + .es-icon,
  label + .es-icon  {
    margin: es-to-rem($es-space-gutter-left-small);
  }
  

  // colors for default button
  & {
    background-color: $es-button-default-background-color;
    border-color: $es-button-default-border-color;
    color: $es-button-default-text-color;


    // colors for contrast backgrounds
    &.-es-contrast {
      background-color: $es-button-default-dark-background-color;
      border-color: $es-button-default-dark-border-color;
      color: $es-button-default-dark-text-color;
    }
    
    // hover and visited state
    &:hover {
      background-color: $es-button-default-hover-background-color;
      border-color: $es-button-default-hover-border-color;
      color: $es-button-default-hover-text-color;
    }

    &:focus {
        border-color: $es-button-default-focus-border-color;
    }

    &:active {
        background-color: $es-button-default-active-background-color;
    }

    // disabled state
    &:disabled,
    &[disabled=disabled] {
      background-color: $es-button-default-disabled-background-color;
      border-color: $es-button-default-disabled-border-color;
      box-shadow: none;
      color: $es-button-default-disabled-text-color;
    }
  }

  // colors for primary button
  &.-es-primary {
    background-color: $es-button-primary-background-color;
    border-color: $es-button-primary-border-color;
    box-shadow: 0px 1px 2px 0px $es-button-primary-box-shadow-color;
    color: $es-button-primary-text-color;

    // hover, focus and visited state
    &:hover{
      background-color: $es-button-primary-hover-background-color;
      border-color: $es-button-primary-hover-background-color;
      box-shadow: 0px 1px 2px 0px $es-button-primary-hover-box-shadow-color;
    }
    &:focus {
      border-color: $es-button-primary-focus-border-color;
      box-shadow: 0px 1px 2px 0px $es-button-primary-hover-box-shadow-color;
    }
    &:active {
        background-color: $es-button-default-active-background-color;
        border-color: $es-button-default-active-background-color;
        color: $es-button-primary-text-color;
    }

    // disabled state
    &:disabled,
    &[disabled=disabled] {
      background-color: $es-button-primary-disabled-background-color;
      border-color: $es-button-primary-disabled-border-color;
      box-shadow: 0px 1px 2px 0px $es-button-primary-disabled-text-color;
      color: $es-button-primary-disabled-text-color;
    }
    &.-es-contrast {
      background-color: $es-button-primary-dark-background-color;
      border-color: $es-button-pimary-dark-border-color;
      color: $es-button-primary-dark-text-color;

      &:hover {
        color: $es-button-primary-text-color;
        background-color: $es-button-primary-hover-background-color;
        border-color: $es-button-primary-hover-background-color;
        box-shadow: 0px 1px 2px 0px $es-button-primary-hover-box-shadow-color;
      }
    }
  }

  // colors for cancel button
  &.-es-danger {
    background-color: $es-button-danger-background-color;
    border-color: $es-button-danger-border-color;
    box-shadow: 0px 1px 2px 0px $es-button-danger-box-shadow-color;
    color: $es-button-danger-text-color;

    // hover, focus and visited state
    &:hover {
      background-color: $es-button-danger-hover-background-color;
      border-color: $es-button-danger-hover-border-color;
    }
    &:focus {
      border-color: $es-button-danger-focus-border-color;
    }
    &:active {
      background-color: $es-button-danger-active-background-color;
      border-color: $es-button-danger-active-background-color;
    }

    // disabled state
    &:disabled,
    &[disabled=disabled] {
      background-color: $es-button-danger-disabled-background-color;
      border-color: $es-button-danger-disabled-border-color;
      box-shadow: 0px 1px 2px 0px $es-button-danger-disabled-box-shadow-color;
      color: $es-button-danger-disabled-text-color;
    }
  }

  &.-es-small {
    padding: es-to-rem($es-space-inset-squish-small);
  }
}
