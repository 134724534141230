//// ----------------------------------------------------------------------
///
/// Mixins/Functions for Widths (used in the Grid and table)
///
/// @group foundation
/// @module scss/modules/foundation/widths
///
//// ----------------------------------------------------------------------


/// Mixin to create width modifiers
//// ----------------------------------------------------------------------
@mixin es-width-create-modifiers($num, $prefix-name, $parent: "") {
    @for $idx from 1 through $num {
        #{$parent}.#{$prefix-name}#{$idx} {
            width: 100% / $num * $idx;
        }
    }
}


/// Widths mixin for controls 
/// --- Sizes are defined in config/widths
//// ----------------------------------------------------------------------


@mixin es-control-width($width) {
  width: $width;
}


