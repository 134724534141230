html {
  font-size: 15px
}

body {
  font-family: $base-font-family;
  line-height: $base-line-height;
  background: #eee;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  p {
    margin: $small-spacing;
  }
}

header {}

footer {}



h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: $heading-font-family;
  // line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  // text-transform: uppercase;
  color: #494a4b;
}


p {
  margin: $small-spacing;

  a {
    text-decoration: underline;
  }
}

a {
  text-decoration: none;
  transition: $base-animation-duration $base-animation-timing;
  //font-size: $base-font-size;
}

.small-text {
  font-size: $font-size-small;
}

.normal-text {
  font-size: $base-font-size;
}

.large-text {
  font-size: $font-size-large;
}
