//// ----------------------------------------------------------------------
///
/// color palette configuration file
///
/// @group configuration
/// @module sass/configuration/foundation/color-palette
///
//// ----------------------------------------------------------------------
$dark-blue: #003865; // ERT Logo Color: Dark-Blue
$atoll: #0d668f;
$light-blue: #0092bc; // ERT Logo Color: Light-Blue
$seagull: #31aacc;
$picton-blue: #4ec3e0;
$blizzard-blue: #a9e1ef;
$polar: #d4f0f6;

$eden: #006d68;
$blue-chill: #148f80;
$mint: #00b398;
$puerto-rico: #3fc2af;
$downy: #6cd3c7;
$powder-mint: #b8e9e3;
$iceberg: #dbf4f1;

$dark-green: #007a33;
$eukalyptus: #469b26;
$pistachio : #84BD00;
$atlantis: #a5c727;
$lime: #c4d600; // ERT Logo Color: Lime
$wild-rice: #e2ea86;
$tusk: #f0f4c1;

$dark-red: #be3a34;
$punch: #dd4c3d;
$orange : #ff5c39;
$burning: #fd7344;
$carrot: #ff8a3d;
$pastell: #fec5a1;
$tuft-bush: #fee2cf;

$dark-violet:#653165;
$wine-berry: #7b3579;
$plum: #93328e;
$tapestry: #a850a6;
$amethyst: #c069c2;
$prelude: #dfb5e0;
$prim: #efdaef;

$black: #1e1e1e;
$abbey: #494a4b;
$dark-grey: #747678;
$oslo-grey: #98999a;
$light-grey: #bcbdbc;
$alto: #dedede;
$gallery: #eeeeee;
$alabaster: #f9f9f9;
$white: #ffffff;


$rbm-blue: (
    50 : #e0e7ed,
    100 : #b3c3d1,
    200 : #809cb2,
    300 : #4d7493,
    400 : #26567c,
    500 : #003865,
    600 : #00325d,
    700 : #002b53,
    800 : #002449,
    900 : #001737,
    A100 : #6e9aff,
    A200 : #3b76ff,
    A400 : #0852ff,
    A700 : #0047ee,
    contrast: (
        50 : #003865,
        100 : #003865,
        200 : #003865,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #003865,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$rbm-light-blue: (
    50 : #e0eded,
    100 : #b3d3d2,
    200 : #80b6b4,
    300 : #4d9995,
    400 : #26837f,
    500 : #006d68,
    600 : #006560,
    700 : #005a55,
    800 : #00504b,
    900 : #003e3a,
    A100 : #74fff3,
    A200 : #41ffee,
    A400 : #0effea,
    A700 : #00f3de,
    contrast: (
        50 : #003865,
        100 : #003865,
        200 : #003865,
        300 : #003865,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #003865,
        A200 : #003865,
        A400 : #003865,
        A700 : #003865,
    )
);

$rbm-green: (
    50 : #e0efe7,
    100 : #b3d7c2,
    200 : #80bd99,
    300 : #4da270,
    400 : #268e52,
    500 : #007a33,
    600 : #00722e,
    700 : #006727,
    800 : #005d20,
    900 : #004a14,
    A100 : #7eff94,
    A200 : #4bff6a,
    A400 : #18ff40,
    A700 : #00fd2c,
    contrast: (
        50 : #003865,
        100 : #003865,
        200 : #003865,
        300 : #003865,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #003865,
        A200 : #003865,
        A400 : #003865,
        A700 : #003865,
    )
);

$rbm-purple: (
    50 : #ede6ed,
    100 : #d1c1d1,
    200 : #b298b2,
    300 : #936f93,
    400 : #7c507c,
    500 : #653165,
    600 : #5d2c5d,
    700 : #532553,
    800 : #491f49,
    900 : #371337,
    A100 : #ff76ff,
    A200 : #ff43ff,
    A400 : #ff10ff,
    A700 : #f600f6,
    contrast: (
        50 : #003865,
        100 : #003865,
        200 : #003865,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #003865,
        A200 : #003865,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);



$rbm-red: (
    50 : #f7e7e7,
    100 : #ecc4c2,
    200 : #df9d9a,
    300 : #d27571,
    400 : #c85852,
    500 : #be3a34,
    600 : #b8342f,
    700 : #af2c27,
    800 : #a72521,
    900 : #991815,
    A100 : #ffcecd,
    A200 : #ff9c9a,
    A400 : #ff6a67,
    A700 : #ff514d,
    contrast: (
        50 : #003865,
        100 : #003865,
        200 : #003865,
        300 : #003865,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #003865,
        A200 : #003865,
        A400 : #003865,
        A700 : #003865,
    )
);

$rbm-mono: (
    50 : #e4e4e4,
    100 : #bcbcbc,
    200 : #8f8f8f,
    300 : #626262,
    400 : #404040,
    500 : #1e1e1e,
    600 : #1a1a1a,
    700 : #161616,
    800 : #121212,
    900 : #0a0a0a,
    A100 : #e07171,
    A200 : #d74747,
    A400 : #eb0000,
    A700 : #d10000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
