//// ----------------------------------------------------------------------
///
/// Gateway Column template
/// @group configuration
/// @module sass/template/gateway-column
///
/// @require flexbox
//// ----------------------------------------------------------------------

.es-gateway-column {
  // Flexbox for columns in login template/page
  .-es-flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .-es-flex-item {
    display: flex;
    flex: 1;
  }
  
  .es-text-group {
    overflow: auto;

    //Overflow handling
    @include es-overflow-wrap;
  }

  .es-gateway-column-footer {
    @extend %es-link-list;

    // deprecated @TODO: Remove in 2.2.0 version
    a + a {
      margin-left: es-to-rem($es-space-default);
    }
    a + a:before {
      content: "|";
      margin: es-to-rem($es-space-gutter-right-default);
    }
  }
}

.es-gateway-column.-es-flex-horizontal {
  // Flexbox for columns in login template/page
    display: flex;
    flex-wrap: wrap;
}

@include es-viewport-range('tablet-up') {
  //@TODO: (WORKAROUND) Buggy in IE, workaround with media query
  .-es-flex-vertical {
    display: flex;
    flex-direction: column;

    & > .es-content-box {
      display: flex;
    }

    & > .es-content-box:first-child {
      flex: 2;
    }
  }
}