// changes to bootstrap
// required to load before bootstrap

$container-max-widths: (
	xs: 400px,
  sm: 540px,
  md: 720px,
	lg: 960px,
	// make "infinite" so no container limit
  xl: 10000000px
);

// changed from default of 30
$grid-gutter-width: 16px;