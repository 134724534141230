//// ----------------------------------------------------------------------
///
/// Table organism
///
/// @group configuration
/// @module sass/organisms/table
///
/// @require function es-color
//// ----------------------------------------------------------------------

// Variables for tables
$es-table-cell-padding:           es-to-rem($es-space-inset-small);
$es-table-header-sort-padding:    es-to-rem($es-space-inset-small + $es-space-gutter-small + ($es-icon-size * .95));
$es-table-side-padding:           es-to-rem($es-space-default);
$es-table-sorting-width:          es-to-rem(4px);
$es-table-status-sign-padding:    es-to-rem($es-space-default + $es-space-small $es-space-default);

// Colors
$es-table-header-color:           es-color("turquoise","blue-chill");
$es-table-header-subtle-color:    es-color("mono", "alto");
$es-table-background-color:       es-color("mono", "alabaster");
$es-table-hover-color:            es-color("mono", "gallery");
$es-table-selected-color:         es-color("blue", "polar");
$es-table-selected-border-color:  es-color("blue", "light-blue");

// Table sorting mixin
@mixin es-table-sorting-icon($icon, $size: $es-icons-font-size-base){
  @include es-icon(ert-icons);
  content: es-get-icon($icon);
  font-size: es-em-calc($size);
  margin: es-to-rem($es-space-gutter-left-small);
  pointer-events: none;
  position: absolute;
}

.es-table {

  border-collapse: collapse;

  // Column width modifiers
  @include es-width-create-modifiers(12, "-es-width-");

  caption,
  th,
  td {
    padding: $es-table-cell-padding;
    vertical-align: middle;
  }

  tr.-es-valign-top,
  td.-es-valign-top,
  th.-es-valign-top {
    vertical-align: top;
  }

  // Spacing for status sign in table
  td .es-status-sign {
    padding: $es-table-status-sign-padding;
  }

  &.-es-plain {
    & > thead {
        background-color: $es-table-header-subtle-color;
        border: 1px solid $es-table-header-subtle-color;
        color: es-color("mono", "black");
    }
  }

  thead {
    background-color: $es-table-header-color;
    border: 1px solid $es-table-header-color;
    color: $es-font-bright-color;

    th {
      font-weight: bold;
      position: relative;
    }
    th:first-child {
      padding-left: $es-table-side-padding;
    }
    th:last-child:not(.-es-sort):not(.-es-sort-descending):not(.-es-sort-ascending) {
      padding-right: $es-table-side-padding;
    }

    .-es-sort,
    .-es-sort-ascending,
    .-es-sort-descending {
      cursor: pointer;
      padding-right: $es-table-header-sort-padding;
      position: relative;
    }
    .-es-sort {
      &:after {
        @include es-table-sorting-icon(sorting, 9.5);
      }
    }
    .-es-sort-ascending {
      &:after {
        @include es-table-sorting-icon(sorting-ascending, 9.5);
      }
    }
    .-es-sort-descending {
      &:after {
        @include es-table-sorting-icon(sorting-descending, 9.5);
      }
    }
  }

  tbody {
    @extend %es-container-background-with-border;
    
    tr {
      & > th:first-child,
      & > td:first-child {
        padding-left: $es-table-side-padding;
      }
      & > td:last-child {
        padding-right: $es-table-side-padding;
      }
    }
  }

  tbody tr:nth-child(odd){
    background-color: $es-table-background-color;
  }

  &.-es-selectable {
    tbody tr.-es-selected {
      background-color: $es-table-selected-color;
      border-left: 1px solid $es-table-selected-border-color;
      border-right: 1px solid $es-table-selected-border-color;

      // Extensions for table
        %es-table-selected-bar {
          background-color: $es-table-selected-border-color;
          bottom: 0;
          content: "";
          position: absolute;
          top: 0;
          width: $es-table-sorting-width;
        }
      & > th,
      & > td {
        position: relative;
      }
      & > *:first-child {
        &:before {
          @extend %es-table-selected-bar;
          left: 0;
        }
      }
      & > *:last-child {
        &:after {
          @extend %es-table-selected-bar;
          right: 0;
        }
      }
    }
    tbody tr:hover {
      background-color: $es-table-hover-color;
    }
    tbody {
      cursor: pointer;
    }
  }
  &.-es-full {
    width: 100%;
  }

  .es-fluid-data {
    @include es-clearfix;
  
    list-style: none;
    margin: 0;
    padding: 0;
  
    @include es-viewport-range(tablet-large-up){
      li{
        float: left;
        width: 50%;

        &:not(:last-child) {
          padding-right: es-to-rem($es-space-default);
        }

/*      IMPROVED SOLUTION:
        Which does not waste space at the end of 3nd Column. But this solution
        would be better in terms of implementation, when we would have a specific
        viewport range which should include everything from tablet to desktop for the 2 column settings, but not above.
        With the current viewport range settings, a reset of the :nth-child(odd) line, as
        the 2 column settings are inherited to desktop-up range resulting in an error.

        &:nth-child(odd) {
          padding-right: es-to-rem($es-space-default);
        }
*/
      }

    }
    @include es-viewport-range(desktop-up){
      li{
        width: 33.3%;

        &:not(:last-child) {
          padding-right: es-to-rem($es-space-default);
        }
/*
        IMPROVED SOLUTION:
        Which does not waste space at the end of 3nd Column. But this solution
        would be better in terms of implementation, when we would have a specific
        viewport range which should include everything from tablet to desktop for the 2 column settings, but not above.
        With the current viewport range settings, a reset of the :nth-child(odd) line, as
        the 2 column settings are inherited to desktop-up range resulting in an error.

        &:nth-child(odd) {
          padding-right: 0;
        }

        &:not(:nth-child(3n+3)) {
          padding-right: es-to-rem($es-space-default);
        }
    */
      }
    }
  }

}