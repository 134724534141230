@use "sass:math";

@import "colors/color-palette";
@import "../vendor-mods/bootstrap-mod";

@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// Typography
$base-font-family: 'Noto Sans', Helvetica Neue,Helvetica,Arial,sans-serif !default;
$heading-font-family: 'Blender Pro', Helvetica Neue,Helvetica,Arial,sans-serif !default;
$base-icon-font-family: 'Material Icons', sans-serif;

// Responsive Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Font Sizes
$base-font-size: 1rem;
$font-size-default:         $base-font-size * 0.8125;
$font-size-small:           $base-font-size * 0.75;
$font-size-large:           $base-font-size * 1.25;
$font-size-section-header:  $base-font-size * 1.75;
$font-size-header:          $base-font-size * 2.50;

// Line height
$base-line-height: $base-font-size * 2;
$heading-line-height: $font-size-header * 2;

// Other Sizes
$base-spacing:  $base-font-size;
$large-spacing: $base-spacing * 2;
$huge-spacing:  $base-spacing * 4;
$small-spacing: math.div($base-spacing, 2); // $base-spacing / 2;
$tiny-spacing:  math.div($base-spacing, 4); // $base-spacing / 4;

$base-z-index: 0;
$navbar-height: 50px;

// Animations
$base-animation-duration: 150ms;
$base-animation-timing: ease;
