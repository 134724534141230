
@import 'normalize.css/normalize';


@import './vendor-mods/bootstrap-mod.scss';
@import 'bootstrap/scss/bootstrap-grid';

// toastr library styles
@import 'ngx-toastr/toastr.css';

@import './base/base';

@import './material/material';

@import './themes/rbm-theme.scss';

@font-face {
  font-family: 'Blender Pro';
  src: url('../assets/fonts/BlenderPro-Bold.otf') format('opentype');
}


@import './vendor-mods/vendor-mods.scss';

/** CODE MIRROR STLESHEETS **/
@import 'codemirror/lib/codemirror';
@import '~codemirror/theme/material';


.center {
  display: flex;
  justify-content:center;
  align-items: center;
}

.flex-spacer {
  flex: 1;
}

.display-flex {
  display: flex !important;
}

.mat-select-panel {
  margin-bottom: -112px;
  border: 1px solid #dedede;
	display: inline-block;
	position: relative;
  top: 10px;
  max-height: 156px !important;
}

.mat-snack-bar-container.mat-notification-backgroud {
    background: $white;
    border-radius: 0 !important;
    max-width: 100vw;
    padding: 3px;
    word-break: break-all;

    //box-shadow: none;

    .mat-simple-snackbar-action {
      height: 100%;
    }

  &.mat-error-background-red {
    border: 1px solid $punch;
    color: $punch;
  }

  &.mat-alert-warning-background {
    border: 1px solid $carrot;
    color: $carrot;
  }

  &.mat-alert-info-background {
    border: 1px solid $light-blue;
    color: $light-blue;
  }

  &.mat-alert-success-background {
    border: 1px solid $mint;
    color: $mint;
  }
}

.inline-error {
  background: #fee2cf;
  color: #dd4c3d;
  line-height: 1.5;
  border: 1px solid $punch;
  padding: .5rem 1rem;
  margin: 0;
}

.mat-card.primary-card {
  padding-top: 0;
}

.pull-card-above {
  position: absolute;
  top: 38px;
  right: 0;
  left: 0;
  margin:0;
  //transition: transform .2s linear;
  transition: transform .5s ease-in-out;
  //background: #eee;
}

.flex-column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.mat-card {
    display: flex;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: .75;

  }

  100% {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;

  }

  100% {
    opacity: 0;
  }
}

.chart-transition {
  animation: fadeIn ease-in-out 1.5s;
  transition: all 1.5s ease;

}

mat-table.ert-table .mat-header-cell {
  font-size: 14px !important;
}

mat-table.ert-table mat-cell.mat-cell {
  font-size: 14px !important;
}