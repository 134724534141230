//// ----------------------------------------------------------------------
///
/// configuration file for spacing system
///
/// @group configuration
/// @module sass/configuration/foundation/spacings
///
//// ----------------------------------------------------------------------

/// Spacing Units in T-Shirt sizes
//// ----------------------------------------------------------------------
/// default spacing unit
/// @type em
/// @require variable $es-pixel-unit
$es-space-default:      $es-pixel-unit;
/// extra small spacing unit (default / 4)
/// @type em
$es-space-xsmall:       $es-pixel-unit/4;
/// small spacing unit (default / 2)
/// @type em
$es-space-small:        $es-pixel-unit/2;
/// medium/default spacing unit
/// @type em
$es-space-medium:       $es-space-default;
/// large spacing unit (default * 2)
/// @type em
$es-space-large:        $es-pixel-unit*2;
/// extra large spacing unit (default * 4)
/// @type em
$es-space-xlarge:       $es-pixel-unit*4;

// Spacing standard variables
$es-gutter: $es-space-large;
$es-content-spacing: $es-space-default;

/// Insets Square
//// ----------------------------------------------------------------------
$es-space-inset-none:       0;
$es-space-inset-default:    $es-space-default;

$es-space-inset-xsmall:     $es-space-xsmall;
$es-space-inset-small:      $es-space-small;
$es-space-inset-medium:     $es-space-inset-default;
$es-space-inset-large:      $es-space-large;
$es-space-inset-xlarge:     $es-space-xlarge;

/// Insets Squish
//// ----------------------------------------------------------------------
$es-space-inset-squish-default:     $es-space-small $es-space-medium;

$es-space-inset-squish-small:       $es-space-xsmall $es-space-small;
$es-space-inset-squish-medium:      $es-space-inset-squish-default;
$es-space-inset-squish-large:       $es-space-medium $es-space-large;
$es-space-inset-squish-xlarge:      $es-space-large $es-space-xlarge;


/// Insets Stretch
//// ----------------------------------------------------------------------
$es-space-inset-stretch-default:    ($es-space-medium + $es-space-small) $es-space-medium;

$es-space-inset-stretch-small:      ($es-space-small + $es-space-xsmall) $es-space-small;
$es-space-inset-stretch-medium:     $es-space-inset-stretch-default;
$es-space-inset-stretch-large:      ($es-space-large + $es-space-medium) $es-space-large;
$es-space-inset-stretch-xlarge:     ($es-space-xlarge + $es-space-large) $es-space-xlarge;


/// Spacings for stacking (vertical)
//// ----------------------------------------------------------------------
$es-space-stack-none:       0;
$es-space-stack-default:    0 0 $es-space-default 0;
$es-space-stack-xsmall:     0 0 $es-space-xsmall 0;
$es-space-stack-small:      0 0 $es-space-small 0;
$es-space-stack-medium:     $es-space-stack-default;
$es-space-stack-large:      0 0 $es-space-large 0;
$es-space-stack-xlarge:     0 0 $es-space-xlarge 0;


/// Inline/Gutter spacings for inline spacings (horizontal)
//// ----------------------------------------------------------------------
$es-space-gutter-none:      0;
$es-space-gutter-default:   $es-space-default;
$es-space-gutter-xsmall:    $es-space-xsmall;
$es-space-gutter-small:     $es-space-small;
$es-space-gutter-medium:    $es-space-gutter-default;
$es-space-gutter-large:     $es-space-large;
$es-space-gutter-xlarge:    $es-space-xlarge;

$es-space-gutter-left-default:   0 0 0 $es-space-gutter-default;
$es-space-gutter-left-xsmall:    0 0 0 $es-space-gutter-xsmall;
$es-space-gutter-left-small:     0 0 0 $es-space-gutter-small;
$es-space-gutter-left-medium:    $es-space-gutter-left-default;
$es-space-gutter-left-large:     0 0 0 $es-space-gutter-large;
$es-space-gutter-left-xlarge:    0 0 0 $es-space-gutter-xlarge;

$es-space-gutter-right-default:   0 $es-space-gutter-default 0 0;
$es-space-gutter-right-xsmall:    0 $es-space-gutter-xsmall 0 0;
$es-space-gutter-right-small:     0 $es-space-gutter-small 0 0;
$es-space-gutter-right-medium:    $es-space-gutter-right-default;
$es-space-gutter-right-large:     0 $es-space-gutter-large 0 0;
$es-space-gutter-right-xlarge:    0 $es-space-gutter-xlarge 0 0;