button[disabled] {
	cursor: not-allowed !important;
}

.es-button {
  mat-icon,
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.button-right {
  float: right;
}

.button-left {
  float: left;
}
