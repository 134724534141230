//// ----------------------------------------------------------------------
///
/// miscellaneous helper modules
///
/// @group modules
/// @module sass/modules/core/helper
///
//// ----------------------------------------------------------------------

//// ----------------------------------------------------------------------
///
/// Mixin that generates clearfix
///
/// @access public

@mixin es-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%es-clearfix{
  @include es-clearfix;
}

//// ----------------------------------------------------------------------
///
/// Mixins that animate hiding and showing
///
/// @access public


@mixin es-transition-hide {
  opacity: 0;
  position: absolute;
  transition: opacity .5s;
  visibility: hidden;
}

@mixin es-transition-show {
  opacity: 1;
  transition: opacity .5s;
  visibility: visible;
}


//// ----------------------------------------------------------------------
///
/// Mixin for borderless overlay
///
/// @access public
@mixin es-overlay {
  background-color: $es-modal-overlay-bg-color;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $es-z-index-overlay;
}

//break words
%es-word-wrap-break-word{
  word-wrap: break-word;
}