/// ----------------------------------------------------------------------
///
/// typography styles
///
/// @group foundation
/// @module scss/foundation/typography
///
//// ----------------------------------------------------------------------


%es-typo-settings-heading {
  font-family: $es-font-headings;
  font-weight: $es-font-weight-headings;
  text-transform: uppercase;
}

%es-typo-settings-h1 {
  @extend %es-typo-settings-heading;
  font-size: es-to-rem($es-h1-font-size);
  line-height: es-to-rem($es-h1-line-height);
  margin: es-to-rem($es-space-stack-default);
}

%es-typo-settings-h2 {
  @extend %es-typo-settings-heading;
  font-size: es-to-rem($es-h2-font-size);
  line-height: es-to-rem($es-h2-line-height);
  margin: es-to-rem($es-space-stack-default);
}

%es-typo-settings-h3 {
  font-size: es-to-rem($es-h3-font-size);
  font-weight: normal;
  line-height: es-to-rem($es-h3-line-height);
  margin: es-to-rem($es-space-stack-small);
}

%es-typo-settings-h4 {
  @extend %es-typo-settings-heading;
  font-size: es-to-rem($es-h4-font-size);
  line-height: es-to-rem($es-h4-line-height);
  margin: es-to-rem($es-space-stack-small);
}

h1 {
  @extend %es-typo-settings-h1;
}

h2 {
  @extend %es-typo-settings-h2;
}

h3 {
  @extend %es-typo-settings-h3;
}

h4 {
  @extend %es-typo-settings-h4;
}

small {
  font-size: es-to-rem($es-small-font-size);
}

p {
  margin: $es-space-stack-large;
}

a {
  color: $es-anchor-color;
  text-decoration: none;

  &:hover{
    color: $es-anchor-hover-color;
  }
  &:focus {
    color: $es-anchor-focus-color;
    opacity: 0.9;
  }
  &.-es-contrast {
    color: $es-font-bright-color;
  }
}
p > a:not(.es-button) {
  text-decoration: underline;
}

ul {
  margin: 0;
  padding: es-to-rem($es-space-gutter-left-large);
  list-style-type: square;
}

