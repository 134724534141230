//// ----------------------------------------------------------------------
/// Non representational
/// color variables
///
/// @group configuration
/// @module sass/configuration/foundation/colors
///
//// ----------------------------------------------------------------------

// Basic colors

/// colors
//// ----------------------------------------------------------------------

// Status
$es-danger-color: es-color("red", "punch");
$es-inactive-color: es-color("mono", "light-grey");
$es-info-color: es-color("blue", "light-blue");
$es-success-color: es-color("turquoise", "mint");
$es-warning-color: es-color("red", "carrot");
$es-warning-light-color: es-color('red', 'tuft-bush');
$es-highlight-color: es-color("blue", "picton-blue");
$es-disabled-color: es-color("mono", "light-grey");
$es-visited-color: es-color('mono', 'dark-grey');

//translucent
$es-glass-color: es-color('mono', 'white', 0.2);

//font
$es-font-color: es-color('mono', 'abbey');
$es-font-alt-bright-color: es-color('green', 'lime');
$es-font-bright-color: es-color('mono', 'white');
$es-font-highlight-color: es-color('turquoise', 'mint');
$es-font-important-color: es-color("blue", "dark-blue");
$es-font-subtle-color: es-color('mono', 'light-grey');
$es-font-warning-color: es-color("red", "punch");

//anchor
$es-anchor-color: es-color('blue', 'light-blue');
$es-anchor-hover-color: es-color('blue', 'picton-blue');
$es-anchor-focus-color: es-color('blue', 'atoll');
$es-anchor-visited-color: es-color('violett', 'wine-berry');
// anchor colors for dark backgrounds
$es-anchor-contrast-color: es-color('mono', 'gallery');
$es-anchor-contrast-hover-color: es-color('mono', 'white');
$es-anchor-contrast-focus-color: es-color('mono', 'alto');
$es-anchor-contrast-visited-color: es-color('mono', 'gallery');

//backgrounds
$es-background-color: es-color("mono", "white");
$es-background-attention-color: es-color('orange', 'maize');
$es-background-contrast-color: es-color("mono", "gallery");
$es-background-disabled-color: es-color("mono", "gallery");
$es-background-light-color: es-color("mono", "alabaster");

//border
$es-border-color: es-color("mono", "light-grey");
$es-border-bright-color: es-color("mono", "white");
$es-border-light-color: es-color("mono", "alto");
$es-border-attention-color: es-color("red", "carrot");
$es-border-separator-color: es-color('mono', 'black', .2);
$es-border-highlight-color: es-color("blue", "picton-blue");

//shadow
$es-shadow-color: es-color('mono', 'black', .2);












