//// ----------------------------------------------------------------------
///
/// Container Modules
///
/// @group partials
/// @module sass/modules/components/container
///
//// ----------------------------------------------------------------------

/* boxed white background */
%es-container-background {
  background-color: $es-background-color;
}

%es-container-background-with-border {
  @extend %es-container-background;
  border: $es-container-border;
}