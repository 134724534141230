//// ----------------------------------------------------------------------
///
/// Mixins/Functions for Spacings
///
/// @group modules/foundation
/// @module scss/modules/foundation/spacings
///
//// ----------------------------------------------------------------------

// extension: modifiers for insets
// 
// Default inset: es-space-inset-large
//// ----------------------------------------------------------------------

%es-inset-modifiers {
    padding: es-to-rem($es-space-inset-small);
    &.-es-inset-xsmall {
        padding: es-to-rem($es-space-inset-xsmall);
    }
    &.-es-inset-default,
    &.-es-inset-small {
        padding: es-to-rem($es-space-inset-small);
    }
    &.-es-inset-medium {
        padding: es-to-rem($es-space-inset-medium);
    }
    &.-es-inset-large {
        padding: es-to-rem($es-space-inset-large);
    }
    &.-es-inset-xlarge {
        padding: es-to-rem($es-space-inset-xlarge);
    }
}

%es-content-box-inset-modifiers {
    @extend %es-inset-modifiers;
}

%es-container-inset-modifiers {
    @extend %es-inset-modifiers;
}

// Squish modifiers

%es-inset-squish-modifiers {
    padding: es-to-rem($es-space-small ($es-space-medium + 5px));
    &.-es-inset-small {
        padding: es-to-rem($es-space-inset-squish-small);
    }
    &.-es-inset-default,
    &.-es-inset-medium {
        padding: es-to-rem($es-space-inset-squish-medium);
    }
    &.-es-inset-large {
        padding: es-to-rem($es-space-inset-squish-large);
    }
    &.-es-inset-xlarge {
        padding: es-to-rem($es-space-inset-squish-xlarge);
    }
}

%es-container-inset-squish-modifiers {
    @extend %es-inset-squish-modifiers;
}


// extension modifiers for vertical alignment
//// ----------------------------------------------------------------------
/*
%es-valign-modifiers {
    &.-es-valign-top {
        vertical-align: top;
    }
    &.-es-valign-middle {
        vertical-align: middle;
    }
    &.-es-valign-bottom {
        vertical-align: bottom;
    }
}
*/
