html {
  box-sizing: border-box;
}

html,
body,
mat-sidenav-container {
  height: 100%;
  overflow: hidden;
}

// reusable loading container
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
}

// re-usable table styles
mat-table.ert-table {
  font-family: $base-font-family;
  box-shadow: none !important;

  .mat-header-row,
  .mat-row {
    padding: 0;
    min-height: 35px;
    border-bottom-width: 0;
    color: #494a4b;
    outline: 0;
  }

  .mat-header-row {
    font-family: $heading-font-family;
    background-color: #dedede;
    color: #494a4b;
  }

  .mat-header-cell {
    font-size: 16px;
    text-transform: capitalize;
    font-family: $base-font-family;
    font-weight: 600;
    padding-left: 8px;
    color: #494a4b;
  }

  button.mat-sort-header-button {
    text-align: left;
    // text-transform: capitalize;
  }

  mat-row {
    align-items: stretch;
    color: #494a4b;
  }

  mat-row:nth-child(even) {
    background-color: #f9f9f9;
  }

  mat-row:nth-child(odd) {
    // background-color: #eee;
  }

  mat-cell.mat-cell {
    color: #494a4b;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    align-items: center;
    min-height: 100%;
    display: flex;
    word-break: break-word;
    font-size: 16px;
    outline: 0;
  }

  div.mat-sort-header-container {
    align-items: center;
  }
}

mat-cell.mat-cell {
  color: #494a4b;
}

// mat card
mat-card.mat-card {
  box-shadow: none !important;
  transition: none;
  border-color: grey;
  border-spacing: 2px;
  border: 1px solid #dedede;
  padding: 15px;
  margin: 10px 0 0 0;
  border-radius: 0;
  color: #494a4b;

  .mat-card-title {
    font-size: 2rem!important;
    font-family: $heading-font-family!important;
  }
}


.mat-card-subtitle,
.mat-card-content {
  font-size: 16px !important;
}

button.mat-raised-button {
  border-radius: 0;
}

mat-option.mat-option {
  font-size: 16px;
}

.mat-select-panel {
  margin-bottom: 0!important;
}

mat-toolbar.mat-toolbar {
  font-size: 16px;
}

mat-drawer-content.mat-drawer-content {
   color: #494a4b;
}
