.chart-legend .legend-label  {
  // color: #003865 !important;
}

.legend-title {
  display:none;
}


.axis text {
  font-weight: bold;
}


.treemap-val {
    display: none !important;
   }

   .mat-toolbar-single-row {
     height: 56px !important;
   }