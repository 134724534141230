/* Angular Material Global styling */


// Angular Material Tab Style
.mat-tab-link {
  &.mat-tab-label-active {
    color: #0092bc;
    opacity: 1;
  }
  
  &:hover {
    color: #4ec3e0;
    opacity: 1;
  }
}
