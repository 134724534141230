@import "./ngx-charts";

// .cls-1 {
//   // fill: white;
//   stroke: white !important;
//
// }
ert-detail .line {
  stroke-width: 3px !important;
}
