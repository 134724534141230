/**
 * Icons
 *
 * @author Axel Pfeuffer
 *
 */

@charset "UTF-8";

/* @TODO: Rework this part here: Needs to be controlled by outer container?
//spacing to span
.es-icon + span {
  margin: es-to-rem($es-space-gutter-small);
}
*/
// icon class for font icons
.es-icon
{
  @include es-ert-icon;
}
// create 2d font icon classes
@include es-create-2d-font-icon-classes($es-icons-2d);