//// ----------------------------------------------------------------------
///
/// Mixin for easier media query generation (screen only)
///
/// @group modules
/// @module sass/modules/core/viewport-range
///
/// @access public
/// @param {String} $viewport-name    Name of viewport contained in viewport configuration "@$es-viewport-ranges"
///
/// @example
///   es-viewport-screen('notebook-only');
///

@mixin es-viewport-range(
  $viewport-name
)
{
  // Constants
  $min: min-width;
  $max: max-width;
  $screen-device-constraint: "screen";

  // prepare media query
  $media-query: $screen-device-constraint;

  // get viewport settings (min-max-range)
  $viewport-settings: map-get($es-viewport-ranges, $viewport-name);

  // expect always two parameters
  @if length($viewport-settings) != 2 {
    @error "Min- and maximum value for #{$viewport-name} must be specified.";
  }

  // get minimum and maximum value from the settings
  $viewport-min: nth($viewport-settings, 1); // minimum value
  $viewport-max: nth($viewport-settings, 2); // maximum value

  // minimum value specified?
  @if $viewport-min {
    // append min condition
    $media-query: $media-query + " and (" + $min + ": " + $viewport-min + ")";
  }

  // maximum value specified?
  @if $viewport-max {
    // append max condition
    $media-query: $media-query + " and (" + $max + ": " + $viewport-max + ")";
  }

  // output media query
  @media #{$media-query}
  {
    @content;
  }
}
