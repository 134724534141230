//// ----------------------------------------------------------------------
///
/// Global Variables for Containers
///
/// @group configuration
/// @module sass/configuration/components/containers
///
//// ----------------------------------------------------------------------

// Container like panels and other

// Panel box-white mixin
$es-container-border-width: 1px;
$es-container-border: $es-container-border-width solid $es-border-light-color;