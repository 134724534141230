//// ----------------------------------------------------------------------
///
/// Map extension functions
///
/// @group modules
/// @module sass/modules/core/map-extensions
///
//// ----------------------------------------------------------------------

/**
 * Extension of map-get, which results a default value, when the specified key does not exist
 */
@function es-map-get-default(
  $map,
  $key,
  $default: null
)
{
  @if map-has-key($map, $key)
  {
    @return map-get($map, $key);
  }
  @else
  {
    @return $default;
  }
}

/**
 * Extension of map-get, which results a default value, when the specified key does not exist
 */
@function es-map-get-combination(
  $map,
  $key1,
  $key2,
  $default: null
)
{
  $value1: null;
  $value2: null;

  @if map-has-key($map, $key1)
  {
    $value1: map-get($map, $key1);
  }
  @if map-has-key($map, $key2)
  {
    $value2: map-get($map, $key2);
  }

  $result: $value1;

  @if $value1 and $value2
  {
    @return $value1 + " " + $value2;
  }
  @else if $value1
  {
    @return $value1;
  }
  @else if $value2
  {
    @return $value2;
  }
  @else
  {
    @return $default;
  }
}

