
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  margin: $small-spacing;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}
