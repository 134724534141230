@use "sass:math";

@function fieldCalcWidth($numCols) {
  // $result: percentage(100/$numCols) - percentage($padding);
  @return (math.div(100, $numCols)) * 1%; // @return (100/$numCols) * 1%;
  // $result: calc($colWidth - $padding);
  // @return $result;
}

.metric-form {
  margin: auto;
  text-align: center;
}

.metric-form-field {
  &.inline-field {
    display: inline-block;
    margin: 20px 50px 0 0;
    width: calc(#{fieldCalcWidth(3)} - (50px));

    .mat-form-field {
      width: 60%;
    }
  }
}


.form-buttons {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

  .left-content {
    margin-right: auto;
    margin-left: 0;

    button:last-child {
      margin-left: 20px;
    }
  }

  .right-content {
    text-align: right;
    margin-right: 0;
    margin-left: auto;

    button:last-child {
      margin-left: 20px;
    }
  }
}
