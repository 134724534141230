//// ----------------------------------------------------------------------
///
/// Button Group Component
///
/// @group component
/// @module sass/components/basics/button-group
///
/// Button Group for joining buttons and button toggle and displaying them in a row
///
//// ----------------------------------------------------------------------

// Styles for toggle buttons
.es-button-group,
.es-button-group-vertical {

  input {
    //don't use display: none or visibility: hidden. Otherwise the checkbox will not be focusable
    //and also not navigable using TAB
    clip: rect(0,0,0,0);
    appearance: none;
    position: absolute;
  }

  input + label {
    @extend .es-button;
  }

  input + label:hover {
    cursor: pointer;
  }

  input:checked + label {
      color: $es-button-default-hover-text-color;
      background-color: $es-button-default-active-background-color;
  }
}

.es-button-group {
  display: inline-flex;

  // For Buttons in a button group
  & > .es-button {
    display: inline-block;
    position: relative;
  }

  & .es-button + .es-button {
    margin-left: -1px;
  }

  // For button toggles in a horizontal button group
  input + label {
    border-left: none;
  }

  label:first-of-type {
      border-left: 1px solid $es-button-default-border-color;
  }
  
}

.es-button-group-vertical {
  display: inline-block;
  float: none;
  position: relative;
  width: auto;

  & > button,
  input + label {
    display: block;
    width:100%;
  }

  & > .es-button + .es-button {
    margin-top: -1px;
  }

  // For button toggles in a vertical button group
  input + label {
    border-top: none;
  }

  label:first-of-type {
    border-top: 1px solid $es-button-default-border-color;
  }
}
