//// ----------------------------------------------------------------------
///
/// typography variables
///
/// @group configuration
/// @module sass/configuration/foundation/typography
///
//// ----------------------------------------------------------------------

$es-font-primary: 'Noto Sans', sans-serif !default;
$es-font-secondary: 'Blender Pro', sans-serif !default;

$es-font-headings: $es-font-secondary;
$es-font-data: $es-font-primary;

///@type px
$es-font-size-default: 13px !default;
///@type px
$es-line-height: 20px !default;

///@type px
$es-small-font-size: 11px !default;
///@type px
$es-small-line-height: 16px !default;

///headings h1/h2/h3/h4
$es-font-weight-headings: 500;
///@type px
$es-h1-font-size: 30px;
///@type px
$es-h1-line-height: $es-h1-font-size * 1.15;
///@type px
$es-h2-font-size: 26px;
///@type px
$es-h2-line-height: $es-h2-font-size * 1.25;
///@type px
$es-h3-font-size: 20px;
///@type px
$es-h3-line-height: $es-h3-font-size * 1.35;
///@type px
$es-h4-font-size: 18px;
///@type px
$es-h4-line-height: $es-h4-font-size * 1.25;