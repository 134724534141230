//// ----------------------------------------------------------------------
///
/// Icon related mixins and functions
///
/// @group modules
/// @module sass/modules/foundation/icon
///
//// ----------------------------------------------------------------------

//// ----------------------------------------------------------------------
///
/// Mixin for font icons
///
/// @access public
/// @param {String} $name    Name of font
///
/// @example
///   @include es-icon('ert-icons');
///
@mixin es-icon($name: ert-icons) {
  display: inline-block;
  font-family: $name; // shortening font declaration
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}

//// ----------------------------------------------------------------------
///
/// Mixin for singleton icons
///
///
@mixin es-icon-singleton() {
  display: inline-block;
  height: 1em;
  //harmonize icons and text
  margin-top: -(es-em-calc(1));
  width: 1em;
}

//// ----------------------------------------------------------------------
///
/// Mixin and placeholder for ERT icons
///
@mixin es-ert-icon(){
  @include es-icon(ert-icons);
  font-size: 1em;
  line-height: 1em;
}
%es-ert-icon{
  @include es-ert-icon;
}
%es-ert-icon-singleton{
  @extend %es-ert-icon;
  @include es-icon-singleton;
}

//// ----------------------------------------------------------------------
///
/// Mixin to create classes/styles for 2d font icons
///
/// @access public
/// @param {Object} $icons    Map of 2d icons
/// @param {String} $icon-prefix    Prefix for each icon class, by default 'icon'
///
/// @example
///   @include es-create-2d-font-icon-classes('dark', 'base', 1.0);
///
@mixin es-create-2d-font-icon-classes(
  $icons,
  $icon-prefix: 'icon'
)
{
  @each $icon-key, $icon-character-value in $icons
  {
    .es-#{$icon-prefix}-#{$icon-key}:before
    {
      content: $icon-character-value; // main icon
    }
  }
}

//// ----------------------------------------------------------------------
///
/// Function to get the character code of an icon
///
/// @access public
/// @param {Map} $map           Map of icons
/// @param {String} $name    Name of icon to be retrieved
///
@function es-get-icon(
  $name,
  $map: $es-icons-2d
)
{
  // icon does not exist?
  @if (map-has-key($map, $name) == false) {
    @error "Icon '#{$name}' does not exist in specified map.";
  }

  $icon: map-get($map, $name);
  @return $icon;
}

/**
 * mixin to create icon color modifiers (color based)
 */
@mixin es-create-icon-color-modifiers(
  $icon-states,
  $default-state: 'default'
)
{
  @each $icon-state-key, $icon-state-color-settings in $icon-states
  {
    $icon-2d-color: map-get($icon-state-color-settings, 'icon-2d');
    $icon-3d-settings: map-get($icon-state-color-settings, 'icon-3d');

    // generator icon selector class
    $icon-selector-class: "&.-es-" + $icon-state-key;

    // key is the default state?
    @if $icon-state-key == $default-state {
      // append selector for layers
      $icon-selector-class: $icon-selector-class + ", &.-es-shadow";

      color: $icon-2d-color;
    }

    // output icon selector class
    #{$icon-selector-class}
    {
      &::before,
      & span::before{
        color: $icon-2d-color;
      }
      &.-es-shadow span::before{ // base
        color: nth($icon-3d-settings, 1);
      }
      &.-es-shadow span + span::before{ // shadow
        color: nth($icon-3d-settings, 2);
      }
      &.-es-shadow span + span + span::before{ // icon-3d
        color: nth($icon-3d-settings, 3);
      }
      // make all spans visible
      & span {
        display: inline-block;
      }
      &.-es-shadow > span {
        display: inline-block;
      }
    }

  }
}